:root {
    --primary-color: #2c3e50;
    --secondary-color: #34495e;
    --accent-color: #1abc9c;
    --background-color: #ecf0f1;
    --text-color: #2c3e50;
    --border-radius: 8px;
    --transition-duration: 0.3s;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}

.dashboard-container {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 250px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: width var(--transition-duration);
}

.sidebar-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.sidebar-button {
    background: var(--secondary-color);
    border: none;
    color: white;
    text-align: left;
    padding: 10px 20px;
    margin: 5px 0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color var(--transition-duration);
    border-radius: var(--border-radius);
}

.sidebar-button:hover {
    background-color: var(--accent-color);
}

.main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: white;
    border-radius: var(--border-radius);
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification {
    background-color: var(--accent-color);
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity var(--transition-duration);
    opacity: 1;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.notification .close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.search-bar {
    display: flex;
    margin-bottom: 20px;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-right: none;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.search-button {
    padding: 10px 20px;
    border: 1px solid var(--primary-color);
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
    transition: background-color var(--transition-duration);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.search-button:hover {
    background-color: var(--secondary-color);
}

.date-filter {
    margin: 20px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.date-filter label {
    margin-right: 10px;
}

.react-datepicker__input-container input {
    width: 200px;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    transition: border-color var(--transition-duration);
}

.react-datepicker__input-container input:focus {
    border-color: var(--accent-color);
    outline: none;
}

.react-datepicker__close-icon::after {
    display: none !important;
}

.custom-datepicker {
    border-radius: var(--border-radius);
}

.date-clear-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: var(--primary-color);
    transition: color var(--transition-duration);
}

.date-clear-button:hover {
    color: var(--accent-color);
}

.table, .total-clicks-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
}

.table th, .table td, .total-clicks-table th, .total-clicks-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.table th, .total-clicks-table th {
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.table th:hover, .total-clicks-table th:hover {
    background-color: var(--primary-color);
}

.table tr:hover, .total-clicks-table tr:hover {
    background-color: #f1f1f1;
}

.flag {
    display: flex;
    align-items: center;
    cursor: pointer;
}
/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: var(--border-radius);
    width: 80%;
    max-width: 900px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-body {
    max-height: 400px;
    overflow-y: auto;
}

.modal-table {
    width: 100%;
    border-collapse: collapse;
}

.modal-table th, .modal-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
