/* Basic reset and font styling */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: #f0f2f5; /* Light grey background for the whole page */
}

/* Centering the login form */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Styling the login form */
.login-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
    max-width: 400px; /* Maximum width of the form */
    box-sizing: border-box;
}

/* Input fields styling */
.login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px; /* Space between inputs */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 5px; /* Rounded corners */
    box-sizing: border-box;
    font-size: 16px;
}

/* Button styling */
.login-form button {
    width: 100%;
    padding: 10px;
    background: hsl(168, 99%, 27%); /* Blue background for the button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s; /* Smooth transition for hover effect */
}

/* Button hover effect */
.login-form button:hover {
    background: hsl(168, 99%, 27%); /* Darker blue on hover */
}
